import React, { Fragment, useState } from "react";
import SearchBox from "../../components/SearchBox/SearchBox";
import ReactPaginate from "react-paginate";
import "./Dashboard.css";
import Select from "react-select";

import previousIcon from "../../assets/left-arrow-icon.png";
import nextIcon from "../../assets/right-arrow-icon.png";
import Navbar from "../../components/Navbar/Navbar";
import InfoModal from "../../components/InfoModal/InfoModal";

const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};
const Dashboard = () => {
  const [openSearchBox, setOpenSearchBox] = useState(false);
  const [activeTab, setActiveTab] = useState("P");

  const changeTabHandler = (tab) => {
    setActiveTab(tab);
  };

  const showSearchBoxHandler = () => {
    setOpenSearchBox(!openSearchBox);
  };

  const [data, setData] = useState([
    {
      invId: "INV001",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV002",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV003",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV004",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV005",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV006",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV007",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV008",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV009",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV0010",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = data.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleStatusChange = (option) => {
    setSelectedOption(option);
  };
  const statusOptions = [
    { value: "P", label: "Paid" },
    { value: "UP", label: "Un Paid" },
  ];

  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="p-5 pt-2 relative">
      <Navbar />
      <div className="p-5 pt-2 ">
        <p className="text-blue-600 text-sm flex gap-2 items-center">
          <span
            className="underline cursor-pointer"
            onClick={showSearchBoxHandler}
          >
            Search Filter
          </span>
          <i
            className="fa-solid fa-magnifying-glass"
            onClick={showSearchBoxHandler}
          ></i>
        </p>
        {/* <div
        className={`w-full overflow-hidden transition-all mt-6 duration-1000 ${
          openSearchBox ? "h-auto" : "h-0"
        }`}
      > */}

        {openSearchBox && <SearchBox />}
        {/* </div> */}
        <div className="flex justify-between mt-6 gap-3 pr-2">
          <div className="border rounded-lg">
            <span
              className={`px-4 py-2 inline-block text-xs cursor-pointer font-semibold rounded-l-lg ${
                activeTab == "P"
                  ? "bg-[#153044] text-white"
                  : "bg-[#fff] text-black"
              }`}
              onClick={() => changeTabHandler("P")}
            >
              Pending
            </span>

            <span
              className={`px-4 py-2 inline-block	text-xs cursor-pointer font-semibold rounded-r-lg ${
                activeTab == "C"
                  ? "bg-[#153044] text-white"
                  : "bg-[#fff] text-black"
              }`}
              onClick={() => changeTabHandler("C")}
            >
              Completed
            </span>
          </div>
          <div className="flex items-center gap-3">
            <i className="fa-solid fa-file-pdf text-2xl text-red-600 cursor-pointer"></i>
            <i className="fa-regular fa-file-excel text-2xl text-green-800 cursor-pointer"></i>
          </div>
        </div>
        <div
          className="relative overflow-x-auto rounded-2xl mt-2"
          style={{ boxShadow: "0px 2px 1px 1px rgba(19, 18, 18, 0.2)" }}
        >
          <table className="w-full text-xs text-left rtl:text-right">
            <thead className="text-md text-white bg-[#153044] cursor-pointer">
              <tr>
                <th scope="col" className="p-4 rounded-l-2xl">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-1 py-2">
                  Invoice ID
                </th>
                <th scope="col" className="px-1 py-2">
                  UUID
                </th>
                <th scope="col" className="px-1 py-2">
                  Document Id
                </th>
                <th scope="col" className="px-1 py-2">
                  Customer Name
                </th>
                <th scope="col" className="px-1 py-2">
                  Buyer Vat Number
                </th>
                <th scope="col" className="px-1 py-2">
                  Invoice Type
                </th>
                <th scope="col" className="px-1 py-2">
                  Issue Date
                </th>
                <th scope="col" className="px-1 py-2">
                  Generated Date
                </th>
                <th scope="col" className="px-1 py-2">
                  Validations Success
                </th>
                <th scope="col" className="px-1 py-2">
                  Requested Date Time
                </th>
                <th scope="col" className="px-1 py-2">
                  Status
                </th>
                <th scope="col" className="px-1 py-2">
                  Overall Txn Value
                </th>
                <th scope="col" className="px-1 py-2">
                  Overall Tax Paid
                </th>
                <th scope="col" className="px-1 py-2">
                  Qr Code Status
                </th>
                <th scope="col" className="px-1 py-2">
                  Invoice Status
                </th>
                <th scope="col" className="px-1 py-2">
                  Warning List
                </th>
                <th scope="col" className="px-1 py-2 rounded-r-2xl">
                  Error List
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white text-md font-semibold border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td>

                    <td className="px-1 py-2">{item.invId}</td>
                    <td className="px-1 py-2">{item.uid}</td>
                    <td className="px-1 py-2">{item.uid}</td>
                    <td className="px-1 py-2">{item.uid}</td>
                    <td className="px-1 py-2">{item.vatNum}</td>
                    <td className="px-1 py-2">{item.invType}</td>
                    <td className="px-1 py-2 text-nowrap">{item.issueDate}</td>
                    <td className="px-1 py-2 text-nowrap">{item.genDate}</td>
                    <td className="px-1 py-2">{item.validationSuccess}</td>
                    <td className="px-1 py-2">{item.issueDate}</td>
                    <td className="px-1 py-2 min-w-[120px]">
                      {" "}
                      <Select
                        value={selectedOption}
                        onChange={handleStatusChange}
                        options={statusOptions}
                        placeholder="Status"
                        styles={customStyles}
                      />
                    </td>
                    <td className="px-1 py-2">0.00</td>
                    <td className="px-1 py-2">0.00</td>
                    <td className="px-1 py-2">{item.qrCode}</td>
                    <td className="px-1 py-2">{item.invStatus}</td>
                    <td className="px-1 py-2 relative text-center text-white text-md cursor-pointer">
                      <button
                        onClick={modalHandler}
                        className="bg-[#153044] py-2 px-5 rounded-lg"
                      >
                        View
                      </button>
                      <span className="absolute py-0.5 top-px	 left-0 px-2 bg-black rounded-full">
                        3
                      </span>
                    </td>
                    <td className="px-1 py-2 relative text-center text-white  text-md cursor-pointer">
                      <button
                        onClick={modalHandler}
                        className="bg-red-600 py-2 px-5 rounded-lg"
                      >
                        View
                      </button>
                      <span className="absolute top-px	 left-0  py-0.5 px-2 bg-black rounded-full">
                        4
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="my-6 flex justify-end">
            <ReactPaginate
              previousLabel={
                <img src={previousIcon} className="w-6" alt="Previous" />
              }
              nextLabel={<img src={nextIcon} className="w-6" alt="Next" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        <div className="mt-4 flex justify-end gap-3">
          <button className="bg-green-800 text-xs	 text-white py-2 px-5 rounded-lg">
            Save
          </button>
          <button className="bg-[#153044] text-xs text-white py-2 px-5 rounded-lg">
            Process
          </button>
        </div>
      </div>
      {showModal && (
        <InfoModal title="Error Info" setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default Dashboard;
