import React, { useState } from "react";
import waldoLogo from "../../assets/erp-logo.png";
import clearTaxLogo from "../../assets/clear-tax-logo.png";
import userIcon from "../../assets/man-user-circle-icon.png";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const logoutHandler = () => {
    navigate("/");
  };
  return (
    <nav className=" p-4 flex justify-between items-center">
      <div className="flex items-center space-x-4">
        <img src={clearTaxLogo} alt="Logo1" className="h-14 w-15" />
        <div className="border-r-2 border-gray-500 h-10"></div>
        <img src={waldoLogo} alt="Logo2" className="h-14 w-15" />
      </div>

      <div className="relative">
        <img
          src={userIcon}
          alt="Profile"
          className="h-10 w-10 rounded-full cursor-pointer"
          onClick={toggleDropdown}
        />
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20">
            <button
              onClick={() => {
                logoutHandler();
                setIsDropdownOpen(false);
              }}
              className="block flex items-center gap-1.5 px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
            >
              <i className="fa-solid fa-power-off text-red-500 text-sm"></i>{" "}
              <span className="font-semibold text-sm ">Logout</span>
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
