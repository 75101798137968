import React, { Fragment, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import loginBg from "../../assets/loginBg.png";
import loginBgBlur from "../../assets/loginBgBlur.png";
import waldoLogo from "../../assets/erp-logo.png";
import clearTaxLogo from "../../assets/clear-tax-logo.png";
const Login = () => {
  // const isAuthenticated = false;
  // if (isAuthenticated) {
  //   return <Navigate to="/home" />;
  // }

  const navigate = useNavigate();

  const goToHome = () => {
    return <Navigate to="/home" />;
  };

  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      goToHome();
    }
  }, []);

  const loginHandler = () => {
    navigate("/home");
  };
  return (
    <Fragment>
      <div
        className="flex h-screen flex-1 flex-col justify-center px-6 py-12 lg:px-8"
        style={{ backgroundImage: `url(${loginBg})`, backgroundSize: "cover" }}
      >
        <div className="absolute top-8 flex items-center space-x-4">
          <img src={clearTaxLogo} alt="Logo1" className="h-16 w-15" />
          <div className="border-r-2 border-gray-500 h-10"></div>
          <img src={waldoLogo} alt="Logo2" className="h-16 w-15" />
        </div>
        <div
          className="mt-10 mb-10 mx-auto sm:w-1/3 py-[50px] rounded-[50px] px-16"
          style={{
            backgroundImage: `url(${loginBgBlur})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <h2 className="mt-5  mb-10  text-center text-6xl font-bold leading-9 tracking-tight text-white">
            Welcome
          </h2>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-white"
              >
                Email ID
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email ID"
                  className="block bg-[#3d459e00] w-full border-0 px-2.5 px-1.5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none  font-bold sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  className="block bg-[#3d459e00] w-full border-0 px-2.5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none  placeholder:text-gray-400 font-bold sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex gap-3 items-center">
                <input id="rem" type="checkbox" />
                <label
                  htmlFor="rem"
                  className="text-white font-semibold text-xs cursor-pointer"
                >
                  Remember me
                </label>
              </div>
              <span className=" text-white font-semibold text-xs cursor-pointer">
                Forgot Password ?
              </span>
            </div>

            <div className="text-center mt-4">
              <button
                onClick={loginHandler}
                className="bg-[#153044] text-white font-semibold py-2 px-8 rounded-lg"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
