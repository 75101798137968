import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import previousIcon from "../../assets/left-arrow-icon.png";
import nextIcon from "../../assets/right-arrow-icon.png";
const InfoModal = ({ title, data, setShowModal }) => {
  const [data1, setData] = useState([
    {
      invId: "INV001",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV002",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV003",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV004",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV005",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV006",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV007",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV008",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV009",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
    {
      invId: "INV0010",
      uid: "123e4567",
      vatNum: "VAT00001",
      invType: "Purchase",
      issueDate: "2023-01-02",
      genDate: "2023-01-02	",
      validationSuccess: "true",
      status: "UnPaid",
      qrCode: "Not Generated",
      invStatus: "Pending",
    },
  ]);
  const closeIssueDateHandler = (event) => {
    const classListArray = Array.from(event.target.classList);
    if (classListArray.includes("info-modal")) {
      setShowModal(false);
    }
  };
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = data1.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(data1.length / itemsPerPage);
  return (
    <div
      onClick={closeIssueDateHandler}
      className="z-50 info-modal fixed w-full h-full bg-slate-500/50 top-0 left-0 flex justify-center"
    >
      <div className="mt-12 w-5/6 h-5/6 rounded-xl overflow-x-auto	 bg-white p-6">
        <div className="flex justify-between">
          <h2 className="text-3xl font-semibold">{title}</h2>
          <i
            className="fa-solid fa-circle-xmark text-3xl cursor-pointer"
            onClick={() => setShowModal(false)}
          ></i>
        </div>
        <div
          className="relative overflow-x-auto rounded-lg mt-6"
          style={{ boxShadow: "0px 2px 1px 1px rgba(19, 18, 18, 0.2)" }}
        >
          <table className="w-full text-xs text-left rtl:text-right">
            <thead className="text-md text-white bg-[#153044] cursor-pointer">
              <tr>
                <th scope="col" className="rounded-l-lg px-1 pl-3 py-2">
                  Error Code
                </th>
                <th scope="col" className="px-1 py-2">
                  Error Message
                </th>
                <th scope="col" className="px-1 py-2">
                  Error Source
                </th>
                <th scope="col" className="px-1 py-2 rounded-r-lg">
                  Path
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((item, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white text-md font-semibold border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="px-1 pl-3 py-2">{item.invId}</td>
                    <td className="px-1 py-2">{item.uid}</td>
                    <td className="px-1 py-2">{item.uid}</td>
                    <td className="px-1 py-2">{item.uid}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="my-6 flex justify-end">
            <ReactPaginate
              previousLabel={
                <img src={previousIcon} className="w-6" alt="Previous" />
              }
              nextLabel={<img src={nextIcon} className="w-6" alt="Next" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
