import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // const redirect = () => {
  //   return <Navigate to="/" />;
  // };
  // useEffect(() => {
  //   const auth = localStorage.getItem("token");
  //   if (!auth) {
  //     redirect();
  //   }
  // }, []);

  return <div>{children}</div>;
};

export default ProtectedRoute;
