import { addDays } from "date-fns";
import React, { Fragment, useState } from "react";
import { DateRange } from "react-date-range";
import Select from "react-select";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment/moment";

const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: 600,
    fontSize: "12px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "12px",
    fontWeight: "600",
  }),
};

const SearchBox = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedInvoiceOption, setSelectedInvoiceOption] = useState(null);
  const [issueDate, setIssueDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const [generatedDate, setGeneratedDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const statusOptions = [
    { value: "P", label: "Paid" },
    { value: "UP", label: "Un Paid" },
  ];
  const invoiceStatusOptions = [
    { value: "pen", label: "Pending" },
    { value: "app", label: "Approved" },
  ];

  const handleStatusChange = (option) => {
    setSelectedOption(option);
  };
  const handleInvoiceStatusChange = (option) => {
    setSelectedInvoiceOption(option);
  };

  const issueDateChangeHandler = (item) => {
    console.log(item);
    setIssueDate(item);
  };

  const [isIssueDateShown, setIsIssueDateShown] = useState(false);
  const issueDateShowHandler = () => {
    setIsIssueDateShown(!isIssueDateShown);
  };
  const closeIssueDateHandler = (event) => {
    const classListArray = Array.from(event.target.classList);
    if (classListArray.includes("issue-date-picker")) {
      setIsIssueDateShown(false);
    }
  };

  //
  const generatedDateChangeHandler = (item) => {
    console.log(item);
    setGeneratedDate(item);
  };

  const [isGeneratedDateShown, setIsGeneratedDateShown] = useState(false);
  const generatedDateShowHandler = () => {
    setIsGeneratedDateShown(!isGeneratedDateShown);
  };
  const closeGeneratedDateHandler = (event) => {
    const classListArray = Array.from(event.target.classList);
    if (classListArray.includes("generated-date-picker")) {
      setIsGeneratedDateShown(false);
    }
  };
  return (
    <Fragment>
      <div className="flex flex-wrap items-center justify-between gap-2 p-1">
        <div className="basis-full sm:basis-[32.5%]">
          <label
            htmlFor="email"
            className="block text-xs font-medium leading-6 text-gray-900"
          >
            Invoice ID:
          </label>
          <div className="mt-1">
            <input
              placeholder="Invoice ID"
              className="block w-full rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ring-offset-blue-300 focus:outline-none focus:ring focus:border-blue-500 text-lg placeholder:text-xs placeholder:text-gray-400 font-semibold sm:text-xs  sm:leading-7"
            />
          </div>
        </div>
        <div className="basis-full sm:basis-[32.5%]">
          <label
            htmlFor="email"
            className="block text-xs font-medium leading-6 text-gray-900"
          >
            Invoice Type:
          </label>
          <div className="mt-1">
            <input
              placeholder="Invoice Type"
              className="block w-full rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring focus:border-blue-500 placeholder:text-gray-400 text-xs font-semibold placeholder:text-xs sm:text-xs sm:leading-7"
            />
          </div>
        </div>
        <div className="basis-full sm:basis-[32.5%]">
          <label
            htmlFor="email"
            className="block text-xs font-medium leading-6 text-gray-900"
          >
            Issue Date:
          </label>
          <div className="mt-1">
            <input
              placeholder=""
              value={`${moment(issueDate[0].startDate).format(
                "DD/MM/YYYY"
              )} - ${moment(issueDate[0].endDate).format("DD/MM/YYYY")} `}
              onClick={issueDateShowHandler}
              readOnly
              className="block w-full cursor-pointer rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring focus:border-blue-500 placeholder:text-gray-400 text-xs font-semibold sm:text-xs sm:leading-7"
            />
          </div>
        </div>
        <div className="basis-full sm:basis-[32.5%]">
          <label
            htmlFor="email"
            className="block text-xs font-medium leading-6 text-gray-900"
          >
            Generated Date:
          </label>
          <div className="mt-1">
            <input
              placeholder=""
              value={`${moment(generatedDate[0].startDate).format(
                "DD/MM/YYYY"
              )} - ${moment(generatedDate[0].endDate).format("DD/MM/YYYY")} `}
              onClick={generatedDateShowHandler}
              readOnly
              className="block w-full cursor-pointer rounded-md border-0 px-2.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring focus:border-blue-500 placeholder:text-gray-400 font-semibold sm:text-xs sm:leading-7"
            />
          </div>
        </div>
        <div className="basis-full sm:basis-[32.5%]">
          <label
            htmlFor="email"
            className="block text-xs font-medium leading-6 text-gray-900"
          >
            Status:
          </label>
          <div className="mt-1">
            <Select
              value={selectedOption}
              onChange={handleStatusChange}
              options={statusOptions}
              placeholder="Status"
              styles={customStyles}
            />
          </div>
        </div>
        <div className="basis-full sm:basis-[32.5%]">
          <label
            htmlFor="email"
            className="block text-xs font-medium leading-6 text-gray-900"
          >
            Invoice Status:
          </label>
          <div className="mt-1">
            <Select
              value={selectedInvoiceOption}
              onChange={handleInvoiceStatusChange}
              options={invoiceStatusOptions}
              placeholder="Invoice Status"
              styles={customStyles}
            />
          </div>
        </div>
      </div>
      <div className="text-right mt-4">
        <button className="bg-[#153044] text-white text-xs font-semibold py-2 px-6 rounded-lg">
          Filter
        </button>
      </div>
      {isIssueDateShown && (
        <div
          onClick={closeIssueDateHandler}
          className="z-50 issue-date-picker fixed w-full h-full bg-slate-500/50 top-0 left-0 flex  justify-center"
        >
          <div className="pt-12">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => issueDateChangeHandler([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={issueDate}
            />
            <div className="text-right  flex gap-3 bg-white p-1.5">
              <button className="bg-[#A8A8A8] basis-1/2 text-white text-xs font-semibold py-2 px-6 rounded-lg">
                Cancel
              </button>
              <button
                onClick={() => setIsIssueDateShown(false)}
                className="bg-[#153044] basis-1/2 text-white text-xs font-semibold py-2 px-6 rounded-lg"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
      {isGeneratedDateShown && (
        <div
          onClick={closeGeneratedDateHandler}
          className="z-50 generated-date-picker fixed w-full h-full bg-slate-500/50 top-0 left-0 flex justify-center"
        >
          <div className="pt-12">
            <DateRange
              editableDateInputs={true}
              onChange={(item) => generatedDateChangeHandler([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={generatedDate}
            />
            <div className="text-right  flex gap-3 bg-white p-1.5">
              <button className="bg-[#A8A8A8]  basis-1/2 text-white text-xs font-semibold py-2 px-6 rounded-lg">
                Cancel
              </button>
              <button
                onClick={() => setIsGeneratedDateShown(false)}
                className="bg-[#153044] basis-1/2 text-white text-xs font-semibold py-2 px-6 rounded-lg"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SearchBox;
